import React from "react";
import classNames from "classnames";
import { Helmet } from "react-helmet";
import { useStaticQuery } from "gatsby";

import { Icon } from "components/Icon";
import { query as SiteLayoutQuery } from "components/layout/SiteLayout";

const HeroWelcome = ({
  iconName = "puzzle",
  title,
  instructions,
  className,
  children,
  ...other
}) => {
  // Populate header, other metadata from gatsby-config.js
  const data = useStaticQuery(SiteLayoutQuery);

  return (
    <div
      {...other}
      className={classNames(
        "px-4",
        "py-3",
        "py-lg-5",
        "text-center",
        "bg-light",
        "d-flex",
        "flex-column",
        "justify-content-center",
        "align-items-center",
        className
      )}
    >
      <Helmet>
        <title>
          {title} | {data.site.metadata.title}
        </title>
      </Helmet>
      <Icon
        name={iconName}
        size={5.5}
        className={classNames("opacity-50", "my-4")}
      />
      <h1 className={classNames("display-5", "fw-bolder")}>{title}</h1>
      <div className="col-lg-6 col-md-8 mx-auto">
        <p className="lead text-dark mb-4">{instructions}</p>
        <div className="d-grid mt-4 mb-4 gap-2 d-sm-flex justify-content-sm-center row">
          {children}
        </div>
      </div>
    </div>
  );
};

export { HeroWelcome };
